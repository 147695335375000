import React from "react";
import withAuth from "../../components/auth";


function PublishersSendersList(props) {
  return (
    <>
      <list-publishers-page />
    </>
  );
}

export default withAuth(PublishersSendersList);