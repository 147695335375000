import React, { Component } from "react";

function PublishersSendersSendGame() {
    
    return (
        <>
            <send-publisher-game-page />
        </>
    );
}

export default PublishersSendersSendGame;